import React from "react";
import { motion } from "framer-motion";
import logo from "../Assets/logo.png";

const Welcome = ({ onNext }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-between min-h-screen bg-white px-4 py-8  "
    >
      {/* Logo Section */}
      <div className="flex items-center gap-2">
        <img src={logo} alt="Logo" className="w-8 mx-auto" />
        <h1 className="text-xl font-bold text-blue-900">crypto.com</h1>|
        <h1 className="text-lg text-gray-400">ONCHAIN</h1>
      </div>

      <div>
        {/* Welcome Text */}
        <div className="text-center flex items-center flex-col">
          <div className="bg-white p-2 border border-gray-100 drop-shadow-lg rounded-[22px]">
            <img src={logo} alt="Logo" className="w-16 mx-auto" />
          </div>
          <h1 className="text-2xl font-semibold mt-6">Welcome</h1>
          <p className="text-gray-600 font-semibold mt-2 te9t-sm">
            A crypto wallet app that gives you full control of your crypto and
            your keys.
          </p>
        </div>

        {/* Buttons Section */}
        <div className="mt-16 flex flex-col w-full items-center space-y-4">
          <button
            className="bg-blue-400 text-white px-6 w-full py-3 rounded-md text-md font-medium shadow-md"
            onClick={() => onNext("legal")}
          >
            Create a new wallet
          </button>
          <button
            className="text-blue-500 font-[400]  text-md"
            onClick={() => onNext("legal")}
          >
            Import an existing wallet
          </button>
        </div>
      </div>
      <div />
    </motion.div>
  );
};

export default Welcome;
