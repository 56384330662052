import React, { useState } from "react";
import Welcome from "./Components/Welcome";
import "./App.css";
import Legal from "./Components/Legal";
import ImportWallet from "./Components/ImportWallet";
import EmailScreen from "./Components/EmailScreen";
import CodeVerification from "./Components/CodeVerification";

function App() {
  const [step, setStep] = useState("welcome");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const renderStep = () => {
    switch (step) {
      case "welcome":
        // return <Welcome onNext={setStep} />;
        return <Welcome onNext={setStep} />;
      case "legal":
        return <Legal onNext={setStep} />;
      case "importWallet":
        return <ImportWallet onNext={setStep} />;
      case "emailScreen":
        return (
          <EmailScreen
            onNext={setStep}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
          />
        );
      case "codeVarification":
        return (
          <CodeVerification
            onNext={setStep}
            email={email}
            setEmail={setEmail}
            setPassword={setPassword}
            password={password}
          />
        );
      default:
        return <Welcome onNext={setStep} />;
    }
  };

  return <div className="max-w-96 mx-auto text-center">{renderStep()}</div>;
}

export default App;
