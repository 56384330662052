import React from "react";
import { motion } from "framer-motion";
import { FiArrowLeft } from "react-icons/fi";

const Legal = ({ onNext }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col justify-between min-h-screen bg-white p-4"
    >
      <div className="!text-left">
        {/* Back Arrow */}
        <button
          className="text-gray-700 !text-left mb-4"
          onClick={() => onNext && onNext("welcome")}
        >
          <FiArrowLeft size={24} />
        </button>

        {/* Legal Section */}
        <div className="flex flex-col items-start">
          <h2 className="text-2xl font-semibold text-gray-900">Legal</h2>
          <p className="text-sm text-left text-gray-500 mt-2">
            Please review the Crypto.com Onchain Terms of Service and Privacy
            Notice.
          </p>
        </div>

        {/* Terms and Privacy Links */}
        <div className="mt-6 w-full border border-gray-200 rounded-lg shadow-md">
          <button className="w-full flex justify-between items-center  p-4 text-left text-gray-900">
            Terms of Service
            <span className="text-gray-400">&#62;</span>
          </button>
          <button className="w-full flex justify-between items-center  p-4 text-left text-gray-900">
            Privacy Notice
            <span className="text-gray-400">&#62;</span>
          </button>
        </div>

        {/* Checkbox and Continue Button */}
        <div className="mt-8">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="mr-2 w-8 h-8 border border-gray-300 rounded"
            />
            <span className="text-xs text-left text-gray-900">
              I’ve read and accept the Terms of Service and Privacy Notice.
            </span>
          </label>
        </div>
      </div>

      <button
        className="bg-blue-600 text-white w-full px-4 py-3 rounded-lg text-sm font-medium"
        onClick={() => onNext && onNext("importWallet")}
      >
        Continue
      </button>

      <div />
    </motion.div>
  );
};

export default Legal;
